<template>
  <div>
    <b-card title="Filter">
      <b-row>
        <b-col
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Mode</label>
          <v-select
            v-model="modeFilter"
            :options="modeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Bank Provider</label>
          <v-select
            v-model="providerFilter"
            :options="providerOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>

        <b-col
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Search Key</label>
          <b-form-input
            id="searchKeyInput"
            v-model="searchKey"
            placeholder="Key Word Here..."
          />
        </b-col>
        <b-col
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Date Range</label>
          <date-picker
            v-model="dateRange"
            value-type="format"
            format="YYYY-MM-DD HH:mm:ss"
            type="datetime"
            range
            confirm
            :clearable="false"
            :shortcuts="shortcuts"
          />
        </b-col>
        <b-col
          md="3"
          class="mb-md-0 mb-2"
        >
          <b-button
            class="mt-2"
            variant="primary"
            @click="doSearch"
          >
            Search
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body>
      <b-table
        class="position-relative"
        responsive
        :fields="bankbillsTableFields"
        :items="records"
        primary-key="trade_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(provider)="data">
          {{ formatProvider(data.item.provider) }}
        </template>

        <template #cell(show_details)="row">
          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show' }}
          </b-form-checkbox>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card-text>
            {{ row.item }}
          </b-card-text>
        </template>

      </b-table>
    </b-card>

    <b-overlay
      :show="loading"
      no-wrap
    />
  </div>
</template>

<script>
import {
  BFormInput, BButton, BCard, BRow, BCol, BTable, BFormCheckbox, BCardText, BOverlay,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import { showToast } from '@/libs/tool'
import moment from 'moment-timezone'
import useBankBillsJs from './bankbills'

const {
  searchBankBillDetail,
} = useBankBillsJs()

export default {
  components: {
    BFormInput,
    BButton,
    BCard,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BCardText,
    BOverlay,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      loading: false,
      searchKey: '',
      records: [],
      dateRange: [],
      shortcuts: [
        {
          text: 'Today',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const end = moment().subtract(1, 'days').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 7 Days',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().subtract(6, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'This Month',
          onClick() {
            const end = moment().endOf('month').endOf('day')
              .toDate()
            const start = moment().startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last Month',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 3 Months',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(3, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
      ],
      bankbillsTableFields: [
        'show_details',
        { key: 'provider', label: 'Provider' },
        { key: 'reference_id', label: 'Partner ID' },
        { key: 'status', label: 'Statue' },
        { key: 'trade_no', label: 'Pagsmile Trade NO.' },
      ],
      providerFilter: '10181025',
      providerOptions: [
        { label: 'BS2-PIX', value: '10181025' },
        { label: 'BS2-PIX-Luxpag', value: '10401025' },
        { label: 'AME-WALLET', value: '10351018' },
        { label: 'BRADESCO-PIX', value: '10031025' },
        { label: 'BS2-Boleto', value: '10181003' },
        { label: 'BS2-Boleto-TransferSmile', value: '11111003' },
        { label: 'RENDIMENTO-PIX', value: '10601025' },
        { label: 'EtPay', value: '11061005' },
        { label: 'PagoEfectivo', value: '1072' },
        { label: 'SPEI-STP', value: '10301042' },
        { label: 'StarkBank-PIX', value: '11201025' },
      ],
      tzFilter: 'Etc/GMT',
      modeFilter: 1,
      modeOptions: [
        { label: 'Partner ID', value: 1 },
        { label: 'Pagsmile Transaction NO.', value: 2 },
        { label: 'Partner Status.', value: 3 },
        { label: 'Clabe.', value: 4 },
      ],
    }
  },
  watch: {
    dateRange: function formatWithTz(object) {
      const startTime = object[0]
      const endTime = object[1]
      const startTzTime = moment.tz(startTime, this.tzFilter)
      const endTzTime = moment.tz(endTime, this.tzFilter)
      const startTimeStr = startTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
      const endTimeStr = endTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')

      const dateArray = new Array(0)
      dateArray.push(startTimeStr, endTimeStr)
      this.$emit('update:dateRange', dateArray)
    },
    modeFilter: function modeOptions(object) {
      if (object === 3 || object === 4) {
        this.dateRange = [moment().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')]
        this.providerFilter = '10301042'
      } else {
        this.dateRange = []
      }
    },
  },
  methods: {
    formatProvider(value) {
      switch (value) {
        case '10181025':
          return 'BS2-PIX'
        case '10401025':
          return 'BS2-PIX-Luxpag'
        case '10351018':
          return 'AME-WALLET'
        case '10031025':
          return 'BRADESCO-PIX'
        case '10181003':
          return 'BS2-Boleto'
        case '11111003':
          return 'BS2-Boleto-TransferSmile'
        case '10601025':
          return 'RENDIMENTO-PIX'
        case '11061005':
          return 'EtPay'
        case '1072':
          return 'PagoEfectivo'
        case '11201025':
          return 'StarkBank-PIX'
        case '10301042':
          return 'SPEI-STP'
        default:
          return 'Unknown'
      }
    },
    doSearch() {
      let [start, end] = this.dateRange
      start = moment.tz(moment.tz(start, this.tzFilter), 'UTC').format('YYYY-MM-DD HH:mm:ss')
      end = moment.tz(moment.tz(end, this.tzFilter), 'UTC').format('YYYY-MM-DD HH:mm:ss')
      this.loading = true
      this.records = []
      const params = {
        mode: this.modeFilter,
        provider: this.providerFilter,
        searchKey: this.searchKey,
        start_date: start,
        end_date: end,
      }
      searchBankBillDetail(
        params,
        records => {
          this.records = records
          this.loading = false
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `Fetch records with ${fail}`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.mx-datepicker-range {
  width: 100% !important;
}
.mx-input {
  height: 37px;
}
</style>
